import * as React from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router-dom';
import AccessLogo from 'Assets/Images/Access_logo.svg';

@observer
export default class ClientValidatePage extends React.Component<RouteComponentProps> {
	public render() {
		let contents = null;

		contents = (
			<div className="body-content login-page">
				<div className="login-form">
					<form className="login">
						<div className="logo-container">
							<img alt="Access Intell Group Logo" src={AccessLogo} />
						</div>
						<div className="title">
							<h5>Awaiting Validation</h5>
						</div>
						<div style={{justifyContent: "center"}}>
							<p style={{fontSize: "14px", textAlign: "justify"}}>
								Our team is validating your account. This process can take up to 24 hours. You will receive an email once your account has been validated.
							</p>
						</div>
					</form>
				</div>
				<div className="login-gradient">
					<div className="login-pattern" />
				</div>
			</div>
		);
		return contents;
	}
}
